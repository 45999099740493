<template>
  <van-row type="flex">
    <van-col span="24">
      <div class="col-wrap pt-17">
        <van-image :src="require('@/assets/images/pay/paySuccess.png')" width="55px" height="55px"/>
      </div>
      <div class="col-wrap pt-4 title-1">
        支付成功
      </div>
      <div class="col-wrap pt-4 title-2">
        我们正在努力打包中…
      </div>

    </van-col>
    <van-col span="24">
      <div class="col-wrap btn-wrap">
        <van-button color="linear-gradient(180deg, #FF5925 0%, #FF2826 88.02%)" block @click="openDialog">查看订单</van-button>
      </div>

    </van-col>
    <open-app ref="openApp" :open-app-uri="openAppUri"/>
    <wechat/>
  </van-row>
</template>

<script>

import OpenApp from "@/components/OpenApp";
import Vue from 'vue';
import {Image, Row, Col, Button} from 'vant';
import Wechat  from '@/components/Wechat'
Vue.use(Image)
Vue.use(Row)
Vue.use(Col)
Vue.use(Button)

export default {
  name: "payResult",
  components: {Wechat, OpenApp},
  data() {
    return {
      openAppUri: '', //打开APP的链接
    }
  },
  methods: {
    // 查看订单
    openDialog() {
      this.openAppUri = 'zhonghui://my.order/list';
      this.$refs.openApp.start();
    },
  }
}
</script>

<style scoped lang="sass">
.col-wrap
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  background-color: #ffffff
  &.title-1
    font-size: 18px
    color: #202020
  &.title-2
    font-size: 14px
    color: #999999
    padding-bottom: 30px

  &.btn-wrap
    display: flex
    flex-direction: column
    padding: 32px 35px
    box-sizing: border-box
    background-color: transparent
    .van-button
      border-radius: 6px
      font-size: 16px

</style>
